<template>
	<div class="help-carousel">
		<carousel
			:loop="true"
			:per-page="1"
			:navigation-enabled="true"
			:navigation-next-label="''"
			:navigation-prev-label="''"			
		>
			<slide v-for="video in videos" :key="video">
				<iframe :src="'https://www.youtube.com/embed/' + video" frameborder="0" allowfullscreen></iframe>
			</slide>
		</carousel>
	</div>	
</template>

<script>
	import { Carousel, Slide } from 'vue-carousel';
	export default {
		name: 'VideoSlider',
		components: {
			Carousel,
			Slide,
		},
		data() {
			return {
				videos: ['vN7HsTpGXy8', '9tq7XoinjTw', 'nTYp5pSsUzI'],
			}
		},
	}
</script>

<style lang="scss" scoped>

	iframe, object, embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

</style>