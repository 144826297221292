<template>
	<div class="help">
		<Promo/>
		<Social/>
		<DialogBox>
			
			<template v-slot:title>
				<h2>Ayuda</h2>
			</template>
			<template v-slot:body>
				<VideoSlider/>
			</template>
			
		</DialogBox>
	</div>
</template>

<script>
	import Promo from '../components/Promo.vue'
	import Social from '../components/Social.vue'
	import DialogBox from '../components/DialogBox.vue'
	import VideoSlider from '../components/VideoSlider.vue'
	export default {
		name: 'Help',
		title: 'Ayuda',
		components: {
			Promo,
			Social,
			DialogBox,
			VideoSlider,		
		}
	}
</script>

<style lang="scss" scoped>

	.help {
		margin-top: -41%;		
	}

	@include tabletLandscape {

		.help {
			margin-top: 20%;
		}
	}

	@include mobile {
		
		.help {
			margin-top: 25%;
			margin-bottom: 5%;
		}		
	}
</style>